import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../shared/model/userModel';
import { Group } from '../shared/model/groupModel';
import { AdministratorService } from '../shared/service/administrator-service';
import { ServiceUrls } from '../shared/common/service-urls';
import { MapUser } from '../shared/model/mapUserModel';
import { Page } from '../shared/model/pageModel';
import { Sector } from '../shared/model/sectorModel';
import { Department } from '../shared/model/departmentModel';
import { Section } from '../shared/model/sectionModel';
import { getSession, getUser, isSessionExpired, refreshSession, removeSession, removeUser, setUser } from '../shared/common/extra-services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  user: User;
  mapUsers: MapUser[] = [];

  public sectors: Sector[] = [];
  public departments: Department[] = [];
  public sections: Section[] = [];

  constructor(private router: Router,
    private adminstrationService: AdministratorService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.getIfLinked();
    this.user = getUser();
    if (this.user != null) {
      this.addToRemove();
      let authPath = this.getUrls(this.user.groupList);

      let path = state.url;

      //Condition cause we project Id can be passed
      if (path.includes('/forms/formProject')) {
        path = '/forms/formProject';
      }
      if (path.includes('/forms/Configuration/InnovationManagement/RatingTemplate')) {
        path = '/forms/Configuration/InnovationManagement/RatingTemplate';
      } else if (path.includes('/forms/Configuration/InnovationManagement')) {
        path = '/forms/Configuration/InnovationManagement';
      }

      if (path.includes('/forms/editTask')) {
        path = '/forms/editTask';
      }

      if (path.includes('/forms/viewTask')) {
        path = '/forms/viewTask';
      }

      let exists = false;

      authPath.forEach(url => {
        if (url == path) {
          exists = true;
        }
      });



      return exists;
    } else {
      const currentRoute = state.url;
      localStorage.setItem('redirectRoute', currentRoute);
      this.router.navigate(['/authentication/login']);
      return false;
    }

  }

  getIfLinked() {
    this.adminstrationService.Get(ServiceUrls.adminGroup.MapUser + "?On=NAN").toPromise().then(res => {

      if (res.responseCode == 200) {
        this.mapUsers = res.response.mapUserList;
        // this.user = getUser();
        this.user = getUser();
        if (this.user != null) {
          let x = this.mapUsers.find(obj => obj.userId == this.user.id);
          if (x != undefined) {

            let extraGroup = this.user.groupList.find(group => group.name == "Extra");

            if (extraGroup == undefined) {

              this.user.on = x.on;
              this.user.onId = x.onId;
              let pageList: Page[] = []
              let page = new Page();
              let group = new Group();
              group.name = "Extra";
              page.path = "/forms/formManageKpi";
              pageList.push(page);
              group.pageList = pageList;
              this.user.groupList.push(group);
              removeUser();
              setUser(this.user);

            }
          } else {
            this.user.on = '';
            this.user.onId = '';
            let extraGroup = this.user.groupList.find(group => group.name == "Extra");
            if (extraGroup != undefined) {
              for (let k = 0; k < this.user.groupList.length; k++) {
                if (this.user.groupList[k].name == 'Extra') {
                  this.user.groupList.pop()
                }
              }
              setUser(this.user);
            }
          }
        }
      }
    });
  }

  getUrls(list: Group[]): string[] {
    let returnedList: string[] = [];
    if (list.length > 0) {
      list.forEach(group => {
        let pageList = group.pageList;
        if (pageList.length > 0) {
          pageList.forEach(page => {
            if (page.path == '/forms/formManageKpi' && group.name != "Extra") {
              if (this.checkIfAuthorized(group)) {
                returnedList.push(page.path);
              }
            } else {
              returnedList.push(page.path);
            }
          });
        }
      });
    }
    return returnedList;
  }

  addToRemove() {
    // if (localStorage.getItem('count') != null) {
    //   let c = +localStorage.getItem('count') + 1;
    //   localStorage.setItem('count', c.toString());
    //   if (c >= 27) {
    //     removeUser();
    //     localStorage.setItem('count', '0');
    //     this.router.navigate(['/authentication/login']);

    //   }
    // } else {
    //   localStorage.setItem('count', '0');
    // }

    if (getSession() != null) {
      if (!isSessionExpired()) {
        refreshSession();
      } else {
        removeSession();
        removeUser();
        this.router.navigate(['/authentication/login']);
      }
    }
  }

  checkIfAuthorized(group: Group): boolean {

    let b = false;
    if (group.code == 'XXX') {
      if (this.sections.length > 0) {
        let userManager = this.sections.find(obj => obj.managerID == this.user.id);
        if (userManager != undefined) {
          b = true;
        }
      } else {
        this.adminstrationService.Get(ServiceUrls.adminGroup.Section).toPromise().then(res => {
          if (res.responseCode == 200) {
            this.sections = res.response.sectionList;
            let userManager = this.sections.find(obj => obj.managerID == this.user.id);
            if (userManager != undefined) {
              b = true;
            }
          }
        });
      }
    } else if (group.code == 'SECM') {
      if (this.departments.length > 0) {
        let userManager = this.departments.find(obj => obj.managerID == this.user.id);
        if (userManager != undefined) {
          b = true;
        }
      } else {
        this.adminstrationService.Get(ServiceUrls.adminGroup.Department).toPromise().then(res => {
          if (res.responseCode == 200) {
            this.departments = res.response.departmentList;
            let userManager = this.departments.find(obj => obj.managerID == this.user.id);
            if (userManager != undefined) {
              b = true;
            }
          }
        });
      }
    } else if (group.code == 'DEPM') {
      if (this.sectors.length > 0) {
        let userManager = this.sectors.find(obj => obj.managerID == this.user.id);
        if (userManager != undefined) {
          b = true;
        }
      } else {
        this.adminstrationService.Get(ServiceUrls.adminGroup.Sector).toPromise().then(res => {
          if (res.responseCode == 200) {
            this.sectors = res.response.sectorList;
            let userManager = this.sectors.find(obj => obj.managerID == this.user.id);
            if (userManager != undefined) {
              b = true;
            }
          }
        });
      }
    }
    return b;
  }
}
