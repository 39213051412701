import { Translation, checkLanguageAdv } from '../common/translation';
import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // Hidden
  // {
  //   path: '/dashboard',
  //   title: 'Dashboard',
  //   titleAr: 'لوحة القيادة',
  //   icon: 'mdi mdi-view-dashboard',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // Hidden
  // {
  //   path: '/sample-pages/profile',
  //   title: 'Profile',
  //   titleAr: 'الملف الشخصي',
  //   icon: 'mdi mdi-view-dashboard',
  //   class: '',
  //   extralink: false,
  //   submenu: []

  // },
  {

    path: '/forms/formStrategy',
    title: 'Strategy',
    titleAr: 'الإستراتيجية',
    icon: 'icon-target',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      // Hidden
      {
        path: '/dashboard/MainKpiDashboard',
        title: 'Main Kpi Dashboard',
        titleAr: 'لوحة القيادة ',
        icon: 'fas fa-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/dashboard/Strategy',
      //   title: 'Strategy Dashboard',
      //   titleAr: 'لوحة قيادة الإستراتيجية',
      //   icon: 'mdi mdi-view-dashboard',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/dashboard/RTA',
        title: 'RTA',
        titleAr: 'RTA',
        icon: 'fas fa-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/dashboard/dashboards',
      //   title: 'Dashboards',
      //   titleAr: 'لوحة النتائج ',
      //   icon: 'fas fa-chart-bar',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/forms/KpiAssignInfo',
        title: Translation.kpiAssignInfo.en,
        titleAr: Translation.kpiAssignInfo.ar,
        icon: 'fas fa-info-circle',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/ApprovalTracking',
        title: Translation.approvalTracking.en,
        titleAr: Translation.approvalTracking.ar,
        icon: 'fas fa-search',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/forms/View/StrategicConnections',
      //   title: 'Strategic Connections',
      //   titleAr: 'الترابط اللإستراتيجي',
      //   icon: 'fab fa-connectdevelop',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },

      // {
      //   path: '/forms/formStrategy',
      //   title: 'Aims and Strategic Objectives',
      //   titleAr: 'الغايات و الأهداف الإستراتيجية',
      //   icon: 'mdi mdi-view-dashboard',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/formStrategy/formBalancedScorecard',
      //   title: 'Balanced Scorecard',
      //   titleAr: 'بطاقة الأداء المتوازن',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: 'has-arrow',
      //   extralink: false,
      //   submenu: [
      //     {
      //       path: '/forms/formStrategy/formBalancedScorecard',
      //       title: 'Summary',
      //       titleAr: 'الخريطة الإستراتيجية',
      //       icon: 'mdi mdi-dots-horizontal',
      //       class: '',
      //       extralink: false,
      //       submenu: []
      //     },
      // {
      //   path: '/forms/formStrategy/formBalancedScorecard/formManageBalancedScorecard',
      //   title: 'Manage Balanced Scorecard',
      //   titleAr: 'إدارة بطاقة الأداء',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
      //   ]
      // },
      // {
      //   path: '/forms/formStrategy/formSwot',
      //   title: 'SWOT',
      //   titleAr: 'التحليل الرباعي',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
    ]
  },
  {
    path: '/forms/manageTasksAndAssignments',
    title: checkLanguageAdv('en', Translation.tasksAndAssignmentsSystem),
    titleAr: checkLanguageAdv('ar-AE', Translation.tasksAndAssignmentsSystem),
    icon: 'fas fa-tasks',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/manageTasksAndAssignments',
        title: checkLanguageAdv('en', Translation.manageTasksAndAssignments),
        titleAr: checkLanguageAdv('ar-AE', Translation.manageTasksAndAssignments),
        icon: 'fas fa-tasks',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/goToTasksDashboard',
        title: checkLanguageAdv('en', Translation.tasksDashboard),
        titleAr: checkLanguageAdv('ar-AE', Translation.tasksDashboard),
        icon: 'fas fa-chart-line',
        class: '',
        extralink: false,
        submenu: [
          {
            path: '/forms/goToTasksDashboard',
            title: '',
            titleAr: '',
            icon: '',
            class: '',
            extralink: false,
            submenu: []
          }
        ],
      },
      // {
      //   path: '/forms/manageTasksAndAssignments',
      //   title: checkLanguageAdv('en', Translation.manageTasksAndAssignments),
      //   titleAr: checkLanguageAdv('ar-AE', Translation.manageTasksAndAssignments),
      //   icon: 'fas fa-file-pdf',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
    ]
  },
  {
    path: '/forms/formPerformance',
    title: 'Performance Management',
    titleAr: 'إدارة الأداء',
    icon: 'fas fa-tachometer-alt',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/forms/OperationalMap',
  //   title: Translation.operationalMap.en,
  //   titleAr: Translation.operationalMap.ar,
  //   icon: 'fas fa-project-diagram',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/forms/formKpi',
    title: 'Admin Kpi List',
    titleAr: 'إدارة المؤشرات',
    icon: 'fas fa-tasks',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/forms/StreamAndPerformanceAgreementKpis',
  //   title: Translation.streamAndPerformanceAgreementKpis.en,
  //   titleAr: Translation.streamAndPerformanceAgreementKpis.ar,
  //   icon: 'fas fa-handshake',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/forms/formManageKpi',
    title: 'Manage Kpi',
    titleAr: 'قائمة المؤشرات',
    icon: 'fas fa-edit',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/forms/formApproval/Pool',
    title: 'Approve Kpi',
    titleAr: 'إعتماد المؤشرات',
    icon: 'fas fa-check',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/forms/ManageMyKpis',
    title: 'Kpis',
    titleAr: ' متابعة المؤشرات',
    icon: 'fas fa-search',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/forms/KpiActualResultVerification',
    title: 'Actual Result Verification',
    titleAr: 'التحقق من النتيجة الفعلية',
    icon: 'fas fa-check-double',
    class: '',
    extralink: false,
    submenu: []
  },
  // Hidden
  // {
  //   path: '/forms/formPerformance/formEmployeeKpi',
  //   title: 'Employee Kpi',
  //   titleAr: 'مؤشرات الموظفين',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/forms/formPerformance/formEmployeeKpi/formAddEmployeeKpi',
  //       title: 'Add New',
  //       titleAr: 'إضافة موظف جديد',
  //       icon: 'mdi mdi-dots-horizontal',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formPerformance/formEmployeeKpi/formUpdateEmployeeKpi',
  //       title: 'Update',
  //       titleAr: 'تحديث',
  //       icon: 'mdi mdi-dots-horizontal',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/forms/formPerformance/formEmployeeKpi/formReviewEmployeeKpi',
  //       title: 'Review',
  //       titleAr: 'المراجعات',
  //       icon: 'mdi mdi-dots-horizontal',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // }
  // },
  // Hidden
  {
    path: '/forms/formProject',
    title: 'Project Management',
    titleAr: 'إدارة المشاريع',
    icon: 'fab fa-react',
    class: '',
    extralink: false,
    submenu: [
      // {
      //   path: '/forms/formProject/formAddProject',
      //   title: 'Add New',
      //   titleAr: 'إضافة مشروع جديد',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/formProject/formUpdateProject',
      //   title: 'Update',
      //   titleAr: 'تحديث',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },
  {
    path: '/forms/Innovation',
    title: Translation.innovation.en,
    titleAr: Translation.innovation.ar,
    icon: 'far fa-lightbulb',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/forms/TeamsManagement',
    title: Translation.teamsManagement.en,
    titleAr: Translation.teamsManagement.ar,
    icon: 'fas fa-users',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/forms/Reports',
    title: Translation.reports.en,
    titleAr: Translation.reports.ar,
    icon: 'fas fa-clipboard-list',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/forms/formAdministration',
    title: 'Manage Hierarchy',
    titleAr: 'إدارة الهيكل التنظيمي',
    icon: 'mdi mdi-account-key',
    class: '',
    extralink: false,
    submenu: [
      // hidden
      // {
      //   path: '/forms/formAdministration/formEmployee',
      //   title: 'Employee',
      //   titleAr: 'الموظفين',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
      // ,
      /********TO BE DELETED *************************/
      // {
      //   path: '/forms/formAdministration/formAggregationMethod',
      //   title: 'Aggregation Method',
      //   titleAr: Translation.aggregationMethod.ar,
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/formAdministration/formCalculationPeriod',
      //   title: 'Calculation Period',
      //   titleAr: Translation.calculationPeriod.ar,
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/formAdministration/formValueType',
      //   title: 'Value Type',
      //   titleAr: Translation.valueType.ar,
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },
  {
    path: '/forms/formInnovation',
    title: 'Innovation',
    titleAr: 'الإبتكار',
    icon: 'mdi mdi-lightbulb-on',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/formInnovation/formSuggestion',
        title: 'Suggestion',
        titleAr: 'الإقتراحات',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formInnovation/formReviewSuggestion',
        title: 'Review Suggestion',
        titleAr: 'مراجعة الإقتراحات',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formInnovation/formCertify',
        title: 'Certify',
        titleAr: 'المصادقة',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '/forms/ExcellenceKpi',
    title: 'Excellence',
    titleAr: 'التميز',
    icon: 'mdi mdi-compass',
    class: '',
    extralink: false,
    submenu: [
      // Hidden
      // {
      //   path: '/forms/ExcellenceKpi/View',
      //   title: 'View',
      //   titleAr: 'عرض مؤشرات التميز',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/ExcellenceKpi/ManageAxis',
      //   title: 'Manage Themes',
      //   titleAr: 'إدارة المحاور',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/ExcellenceKpi/ManageMainStandard',
      //   title: 'Manage Main Standard',
      //   titleAr: 'إدارة المعايير الرئيسية',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/forms/ExcellenceKpi/ManageSubStandard',
      //   title: 'Manage Sub Standard',
      //   titleAr: 'إدارة المعايير الفرعية',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },
  {
    path: '/forms/ProcessesAndProcedures',
    title: Translation.processesAndProcedures.en,
    titleAr: Translation.processesAndProcedures.ar,
    icon: 'fas fa-project-diagram',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/forms/MysteryShopper',
  //   title: Translation.mysteryShopper.en,
  //   titleAr: Translation.mysteryShopper.ar,
  //   icon: 'fas fa-user-secret',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },

  // {
  //   path: '/forms/StrategicKpis',
  //   title: 'Strategic Maps',
  //   titleAr: 'الخرائط الإستراتيجية',
  //   icon: 'mdi mdi-target',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/forms/Configuration',
    title: 'Administration',
    titleAr: 'إدارة النظام',
    icon: 'mdi mdi-wrench',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      // Hidden
      // {
      //   path: '/forms/Configuration/Strategy',
      //   title: 'Strategy',
      //   titleAr: 'الإستراتيجية',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: 'has-arrow',
      //   extralink: false,
      //   submenu: []

      // },
      {
        path: '/forms/Configuration/PerformanceManagement',
        title: 'Performance Management',
        titleAr: 'إدارة الأداء',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/Configuration/ProjectManagement',
        title: 'Project Management',
        titleAr: 'إدارة المشاريع',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/Configuration/InnovationManagement',
        title: Translation.innovationConfiguration.en,
        titleAr: Translation.innovationConfiguration.ar,
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/forms/Configuration/MysteryConfiguration',
      //   title: Translation.mysteryShopperConfigurationManagement.en,
      //   titleAr: Translation.mysteryShopperConfigurationManagement.ar,
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/forms/Configuration/SystemPermissions',
        title: 'System Permissions',
        titleAr: 'صلاحيات النظام',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/Configuration/ApprovalCycle',
        title: 'Approval Cycle',
        titleAr: 'خط سير الإعتماد',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/Configuration/NotificationManagement',
        title: 'Approval Cycle',
        titleAr: 'إدارة التنبيهات',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/forms/SupportTicket',
      //   title: 'Support Ticket',
      //   titleAr: 'الدعم الفني',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/forms/Configuration/SupportTicket',
        title: 'Support Ticket',
        titleAr: 'الدعم الفني',
        icon: 'mdi mdi-dots-horizontal',
        class: '',
        extralink: false,
        submenu: []
      },
      // Hidden
      // {
      //   path: '/forms/Configuration/ProjectManagement',
      //   title: 'Project Management',
      //   titleAr: 'إدارة المشاريع',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: 'has-arrow',
      //   extralink: false,
      //   submenu: []

      // },
      // {
      //   path: '/forms/Configuration/Risk',
      //   title: 'Risk',
      //   titleAr: 'التهديات',
      //   icon: 'mdi mdi-dots-horizontal',
      //   class: 'has-arrow',
      //   extralink: false,
      //   submenu: []

      // }

    ]
  },
  // {
  //   path: '/forms/SystemDevelopmentTasks',
  //   title: 'System Development Tasks',
  //   titleAr: 'مهام تطوير النظام',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/forms/SupportTicket',
    title: 'Support Ticket',
    titleAr: 'الدعم الفني للنظام',
    icon: 'fas fa-ticket-alt',
    class: '',
    extralink: false,
    submenu: []
  }
];



